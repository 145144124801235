// extracted by mini-css-extract-plugin
export var ButtonWrapper = "SeConnecter-module--ButtonWrapper--Ni-uo";
export var ForgottenPasswordWrapper = "SeConnecter-module--ForgottenPasswordWrapper--Pnx09";
export var InformationsWrapper = "SeConnecter-module--InformationsWrapper--ZKI3F";
export var InputWrapper = "SeConnecter-module--InputWrapper--gdhfs";
export var RightSide = "SeConnecter-module--RightSide--Dylx6";
export var RowWrapper = "SeConnecter-module--RowWrapper--1pWNB";
export var Spacer = "SeConnecter-module--Spacer--fT9gZ";
export var SubtitleWrapper = "SeConnecter-module--SubtitleWrapper--RKB7K";
export var TitleWrapper = "SeConnecter-module--TitleWrapper--6l75l";
export var Wrapper = "SeConnecter-module--Wrapper--2LU60";