import React, { useEffect, useState } from "react";
import Layout from "./../../components/Layout";
import SeConnecter from "./../../nav/SeConnecter";
import { Helmet } from "react-helmet";
import { getUser } from "./../../auth";
import { TailSpin } from "react-loader-spinner";
import firebase from "gatsby-plugin-firebase";

const isBrowser = typeof window !== "undefined";

const WhichProfile = ({ user }) => {
  if (user.subscription.type === "admin") {
    if (isBrowser) {
      window.location.href = "/admin/profil";
    }
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else {
    if (isBrowser) {
      let arg = new URLSearchParams(window.location.search).get("action");
      if (arg === "gold") {
        const newUser = {
          subscription: {
            end: parseInt(user.subscription.end),
            start: parseInt(user.subscription.start),
            type: user.subscription.type,
            status: "payed",
          },
        };
        firebase
          .firestore()
          .collection("users")
          .doc(user.id)
          .update(newUser)
          .then(() => {
            // console.log(user.subscription)
            // window.location.href = "https://buy.stripe.com/test_3cs9CZe9r1kh7mMfYZ" // Test
            window.location.href = "https://buy.stripe.com/3cscMP0Y33UvdNKfZ0"; // PROD
          });
      } else {
        window.location.href = "/utilisateurs/profil";
      }
    }
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  }
};

const SeConnecterPage = () => {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    getUser(setUser);
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Se connecter</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Sur cette page,
          tu trouveras un formulaire pour te connecter à ton compte WYNIT."
        />
        <meta name="keywords" content="réductions,jeunes,connexion,compte" />
        <link rel="canonical" href="https://wynit.com/se-connecter" />
      </Helmet>
      {user === undefined ? (
        <div style={{ textAlign: "center" }}>
          <TailSpin color="#FF8000" height={200} width={200} />
        </div>
      ) : user ? (
        <WhichProfile user={user} />
      ) : (
        <SeConnecter setUser={setUser} />
      )}
    </Layout>
  );
};

export default SeConnecterPage;
