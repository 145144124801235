import React, { useState } from "react"
import {
  Wrapper,
  TitleWrapper,
  SubtitleWrapper,
  InputWrapper,
  RowWrapper,
  Spacer,
  ForgottenPasswordWrapper,
  ButtonWrapper,
} from "./SeConnecter.module.css"
import {
  PaddingTop50,
  PaddingTop20,
  PaddingTop10,
  PaddingBottom10,
} from "./../../css/Padding.module.css"
import { classNames } from "../../helpers/classNames"
import Button from "./../../components/Button"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SeConnecter = ({ setUser }) => {
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleFinishButton = () => {
    if (email.length > 0 && password.length > 0) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(result => {
          //console.log(result)
          setSuccessSnackbarIsOpened(true)
        })
        .catch(err => {
          //console.log("error during sign in: ", err)
          setUser(null)
          setErrorSnackbarIsOpened(true)
        })
    } else {
      setErrorSnackbarIsOpened(true)
    }
  }

  const handleEnterPressed = event => {
    if (event.key === "Enter") {
      handleFinishButton()
    }
  }

  return (
    <div className={Wrapper}>
      <h4 className={TitleWrapper}>Se connecter</h4>
      <p className={SubtitleWrapper}>
        Connecte-toi avec ton adresse email et ton mot de passe.
      </p>
      <div className={classNames([InputWrapper, PaddingTop50])}>
        <div className={PaddingBottom10}>
          <label htmlFor="email">Email</label>
        </div>
        <input
          type="name"
          placeholder="nom@example.com"
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className={classNames([InputWrapper, PaddingTop20])}>
        <div className={PaddingBottom10}>
          <label htmlFor="password">Mot de passe</label>
        </div>
        <input
          type="password"
          placeholder="Ton mot de passe"
          onChange={e => setPassword(e.target.value)}
          onKeyPress={handleEnterPressed}
        />
      </div>
      <div className={classNames([RowWrapper, PaddingTop10])}>
        <div className={Spacer}></div>
        <a
          className={ForgottenPasswordWrapper}
          href="/se-connecter/mot-de-passe-oublie"
        >
          Mot de passe oublié ?
        </a>
      </div>
      <div className={ButtonWrapper}>
        <Button alt="" onClick={() => handleFinishButton()}>
          Se connecter
        </Button>
      </div>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Une erreur est survenue. Vérifie les informations que tu as
          rentré.
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bienvenue sur ton profil !
        </Alert>
      </Snackbar>
    </div>
  )
}

export default SeConnecter
